// import dayGridPlugin from '@fullcalendar/daygrid'
// import iCalendarPlugin from '@fullcalendar/icalendar'


jQuery(document).ready(function ($) {
    // var dayGridPlugin = require('../../../node_modules/@fullcalendar/daygrid')
    // var iCalendarPlugin = require('../../../node_modules/@fullcalendar/icalendar')
    //var calendarEl = document.getElementById("calendar")

    //var calendar = new Calendar(calendarEl, {
    //plugins: [dayGridPlugin, iCalendarPlugin],
    // events: {
    //     url: 'https://sync.infomaniak.com/calendars/IE00203/0ad60988-2b7f-4942-9d67-34411d1158c5?export',
    //     format: 'ics'
    // }
    //})
    //calendar.render()

    // document.addEventListener('DOMContentLoaded', function() {
    //     var calendarEl = document.getElementById('calendar');
    //     var calendar = new FullCalendar.Calendar(calendarEl, {
    //       initialView: 'dayGridMonth'
    //     });
    //     calendar.render();
    //   });


	/* Mobile Menu
    * https://mmenujs.com/mmenu-light/
    */
    function mobileMenu() {
        if ($(window).width() <= 1199) {
            $('#menu-main-container').mmenu({
                // options
                "extensions": [
                    "position-right",
                    "fullscreen",
                    "position-front",
                    "border-none"
                ]
            }, {
                offCanvas: {
                    clone: true,
                    blockUI: false,
                    page: {
                        selector: '#my-page'
                    }
                }
            });

            $('#mm-menu-main-container .mm-panels').addClass('container');
            $('.header__nav--options').prependTo('#mm-0');
        } else {
            $('.header__nav--options').prependTo('#header .nav__container');
        }
    }

    mobileMenu();

    $(window).resize(function () {
        mobileMenu();
    })

    $('#menu-mobile-trigger').on('click', function () {
        $(this).toggleClass('open');
        $('#mm-menu-main-container').toggleClass('mm-menu_opened');
        $('#header').toggleClass('is-open');
        $('body').toggleClass('unscroll');
        $('html').toggleClass('unscroll');
    });

    var heroNavMobileItem = $('.hero__nav_mobile');
    var heroNav = $('.hero__nav');

    heroNavMobileItem.on('click', function() {
        if ($(this).hasClass('is-active')) {
            $(this).removeClass('is-active');
            heroNav.removeClass('is-open');
        } else {
            $(this).addClass('is-active');
            heroNav.addClass('is-open');
        }
    });




    // Workshop waiting list form
    if ($('.page--ateliers').length || $('.page--atelier').length) {
        var popup = $('.workshop-popup')
        
        $(document).on("click", ".workshop__date__availability.waiting_list", function () {
            var button = $(this)
            var workshopName = button.data('name')
            var workshopDate = button.data('date')

            popup.find('h3.workshop_name').text(workshopName)
            popup.find('#input_11_10').val(workshopName)
            popup.find('h3.workshop_date').text(workshopDate)
            popup.find('#input_11_11').val(workshopDate)

            popup.addClass('is-active')
        })

        var popupClose = $('.popup-close')
        popupClose.on('click', function () {
            popup.removeClass('is-active')
        })
    }

    /**
     * Slick carousels
     * http://kenwheeler.github.io/slick/
     */
    // Starter Page Header
    $('#historique__slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus: false,
        pauseOnHover: false,
        dots: false,
        arrows: true,
        autoplay: false,
        adaptiveHeight: false,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    arrows: false,
                }
            },
        ]
    });

    

    /**
    * Expositions filter
    */

     if (document.querySelector('body').classList.contains('page-template-template-expositions')) {
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        const moreButton = document.getElementById('expositionsLoadMore');
        let expositions = document.getElementsByClassName('exposition--archived');
        let inputLocation = document.getElementById('location');
         let inputVisibility = document.getElementById('visibility');
         
         

        function toggleLocation() {
            for (let expo of expositions) {
                if (!expo.classList.contains('location')) {
                    expo.classList.toggle('hidden');
                }
            }
        }

        function toggleVisibility() {
            for (let expo of expositions) {
                if (!expo.classList.contains('visibility')) {
                    expo.classList.toggle('hidden');
                }
            }
        }

        function hideExpo() {
            for (let expo of expositions) {
                if (!expo.classList.contains('location') && !expo.classList.contains('visibility')) {
                    expo.classList.add('hidden');
                }
            }
        }
         
         function hideMoreButton() {
             moreButton.classList.toggle('hidden')
         }

        for (let checkbox of checkboxes) {
            checkbox.addEventListener('change', () => {
                if (checkbox === inputLocation) {
                    toggleLocation();
                    if (inputVisibility.checked) {
                        hideExpo();
                        hideMoreButton()
                    }
                } else if (checkbox === inputVisibility) {
                    toggleVisibility();
                    if (inputLocation.checked) {
                        hideExpo();
                        hideMoreButton()
                    }
                }
                hideMoreButton();
            });
        }

        /**
        * Load More Expositions button
        */
        const expositionsLoadMoreButton = document.getElementById('expositionsLoadMore');
        const hiddenExpositions = [...document.querySelectorAll('.exposition--archived.not-loaded')];
    
        loadMore(expositionsLoadMoreButton, hiddenExpositions, 8, 8);
    }
    

     /**
     * Add button class to input submit
     */

    if (document.querySelector('input[type="submit"]')) {
        const submitInputs = document.querySelectorAll('input[type="submit"]');
        for (let submitInput of submitInputs) {
            submitInput.outerHTML = submitInput.outerHTML.replace(/^\<input/, '<button') + submitInput.value + '</button>';
        }
        const newSubmitButton = document.querySelector('button[type="submit"]');
        newSubmitButton.classList.add('btn', 'btn--smaller-padding', 'btn--border');
    }
    

    function loadMore(button, allElements, initialNumberVisibleElements, numberAdditionalElements) {
        allElements.splice(0, initialNumberVisibleElements).forEach(
            elem => elem.classList.remove('not-loaded')
        );

        if (button) {
            button.addEventListener('click', function(e) {
                e.preventDefault();
                allElements.splice(0, numberAdditionalElements).forEach(
                    elem => elem.classList.remove('not-loaded')
                )
                if (allElements.length === 0) {
                    button.classList.add('hidden');
                }
            });
        }
    }

    

    /**
    * Load More Programmation button
    */
    if (document.querySelector('body').classList.contains('page-template-template-homepage')) {
        const programmationLoadMoreButton = document.getElementById('eventsLoadMore');
        const hiddenEvents = [...document.querySelectorAll('.programm.not-loaded')];
    
        loadMore(programmationLoadMoreButton, hiddenEvents, 4, 2);
    }

    /**
    * WPML languages menu toggle arrow down
    */

    const languagesArrowIcon = document.getElementById('arrowLanguagesMenu');
    const listLanguages = document.getElementById('availableLanguages');

    if (listLanguages && languagesArrowIcon) {
        if(listLanguages.children.length === 0) {
            languagesArrowIcon.style.display = 'none';
        } else {
            languagesArrowIcon.style.display = 'initial';
        }
    }

    /**
     * WooCommerce => add class to buttons
     */
    if (document.querySelector('body').classList.contains('woocommerce')) {
        const checkoutButtons = document.querySelectorAll('.checkout-button');
        const wooCommerceButtons = document.querySelectorAll('.woocommerce .actions .button');
        for (let checkoutButton of checkoutButtons) {
            checkoutButton.classList.add('btn', 'btn--border', 'btn--inherit-woocommerce');
            wooCommerceButtons.classList.add('btn', 'btn--border', 'btn--inherit-woocommerce');
        }
    }

});
